import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Progress Circle component is a visual representation of the completion status of a task or process in the form of a circular progress indicator. It provides users with a dynamic and intuitive way to track progress.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progresscircle/guideline-progress-circle-anatomy.svg",
      "alt": "anatomy navbar"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Circular Track: The background circle that represents the entire process.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Progress Arc: The filled portion of the circle that grows as the task progresses, forming an arc.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Percentage Indicator (Optional): A numerical representation of the completion percentage displayed within or near the progress circle.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Label (Optional): A brief label or description of the task being tracked.`}</p>
      </li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Time Picker component can be used in various contexts, such as:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`File Uploads: Show the progress of file uploads, download, or data transfer.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Loading Indicators: Indicate the loading progress of web pages, features, or content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Multi-step Processes: Display the completion status of multi-step workflows, wizards, or onboarding.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Survey or Quiz Progress: Communicate the progress of surveys, questionnaires, or quizzes.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Common places buttons appear`}</strong>{` : Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>Visual Engagement is Preferred: A dynamic and visually appealing progress indicator enhances user engagement.</li>
      <li>Circular Progress is Intuitive: The task's nature lends itself well to a circular representation (e.g., loading a circular object).</li>
      <li>Real-time Feedback is Essential: You need to provide immediate feedback on the progress of a task or process.</li>
      <li>Improved User Experience is Required: It helps reduce user uncertainty and frustration, especially during longer tasks.</li> 
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>Space Constraints: Limited screen space may not accommodate a circular progress indicator, making it impractical.</li>
      <li>Irrelevant to User Experience: When progress tracking doesn't significantly impact the user's interaction or understanding of the task.</li>
      <li>Incompatibility with Task Type: For tasks that do not naturally align with circular representation (e.g., linear processes).</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`To ensure accessibility, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Provide alternative text for screen readers to describe the progress.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensure keyboard navigation is supported, allowing users to interact with the progress circle using keyboard controls.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Maintain sufficient color contrast between the progress arc and background for users with visual impairments.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Test with screen readers and assistive technologies to verify accessibility.`}</p>
      </li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use clear and descriptive labels if including a label or percentage indicator." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progresscircle/guideline-progress-circle-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t provide an indicator progress for the progress bar" aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progresscircle/guideline-progress-circle-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Progress Circle :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Label: If including a label, keep it brief and informative.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Percentage Indicator: If displaying a percentage, use clear formatting and ensure it updates in real-time.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Alternative Text: For accessibility, provide alternative text that communicates the progress status.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Color Coding: If using color to distinguish between completed and remaining progress, select accessible colors.`}</p>
      </li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use color to distinguish between completed and remaining progress, choose colors that are meaningful and intuitive." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progresscircle/guideline-progress-circle-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t use the correct color to distinguish between completed and remaining progress, choose colors that are meaningful and intuitive." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/progresscircle/guideline-progress-circle-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      